import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { resolveDeadlineVariant } from '@/views/financial/payment-remittance-list/usePaymentRemittanceList'
import { maskCnpj } from '@core/utils/utils'

export default function useUserList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'actionsCheck', label: 'Ações' },
    { key: 'contract_code', label: 'CONTRATO', sortable: true },
    { key: 'signed_at', label: 'DATA', sortable: true },
    {
      key: 'user', sortable: true, label: 'SOLICITANTE', sortKey: 'name',
    },
    { key: 'cpf', label: 'CPF', sortable: true },
    { key: 'amount_physician_duty', label: 'VALOR', sortable: true },
    { key: 'account', sortable: false, label: 'Conta' },
    { key: 'institution', label: 'Instituição' },
    { key: 'deadline', label: 'PRAZO' },
    { key: 'actions', label: 'Ações' },
  ]
  const perPage = ref(30)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 30, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('name')
  const showImediato = ref(false)
  const isSortDirDesc = ref(true)
  const situationFilter = ref(null)
  const statusFilter = ref(null)
  const contractList = ref([])
  const remittanceTotal = ref(0)
  const institutionId = ref('')
  const institutionOptions = ref([])

  const checkAll = computed({
    get: () => {
      if (contractList.value.length === 0) return false
      return contractList.value.filter(user => user.checked).length === contractList.value.length
    },
    set: val => {
      contractList.value.forEach(user => {
        // eslint-disable-next-line no-param-reassign
        user.checked = val
      })
    },
  })

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, situationFilter, statusFilter, showImediato, institutionId], () => {
    refetchData()
  })

  watch(contractList, () => {
    remittanceTotal.value = contractList.value
      .filter(user => user.checked)
      .reduce((amount, item) => amount + item.amount_physician_duty, 0)
  }, { deep: true })

  const contractNotInPaymentRemittance = (ctx, callback) => {
    store
      .dispatch('app-contract/contractNotInPaymentRemittance', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDirection: isSortDirDesc.value ? 'desc' : 'asc',
        situation: situationFilter.value,
        status: statusFilter.value,
        show_imediato: showImediato.value ? 1 : 0,
        institution: institutionId.value ? institutionId.value.value : undefined,
      })
      .then(response => {
        const { data: contracts } = response.data.data
        contractList.value = contracts.map(user => {
          const p = { ...user, status: 'active', checked: false }
          p.cpf = p.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '***.$2.$3-**')
          return p
        })
        callback(contractList.value)
        totalUsers.value = response.data.data.meta.pagination.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao buscar',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const createPaymentRemittance = async paymentDate => {
    const items = contractList.value.filter(contract => contract.checked).map(contract => ({ contract_id: contract.id, operation: null }))
    try {
      const { data } = await store
        .dispatch('app-contract/createPaymentRemittance', {
          items,
          payment_date: paymentDate,
        })
      return {
        id: data.data.id,
        code: data.data.code,
      }
    } catch (err) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Erro',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
    return null
  }

  const fetchInstitutions = () => {
    store.dispatch('app-reports/fetchInstitutions', { perPage: 99999999 })
      .then(response => {
        institutionOptions.value = response.data.data.map(inst => ({
          value: inst.id,
          label: `${inst.name} | ${maskCnpj(inst.cnpj)}`,
        }))
      })
  }

  return {
    contractNotInPaymentRemittance,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    refetchData,
    fetchInstitutions,
    institutionId,
    institutionOptions,

    // Extra Filters
    situationFilter,
    statusFilter,
    checkAll,
    showImediato,
    createPaymentRemittance,
    resolveDeadlineVariant,
    remittanceTotal,
  }
}
